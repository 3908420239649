import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnInit,
    ViewChild,
    ViewEncapsulation,
} from "@angular/core";
import { TypeManagerDecorator } from "../../../main/type.map.service";
import { MatchFriendContact, MatchProfile, Question, StudentHousingService } from "../student.housing.service";
import { Table } from "../../s25-table/Table";
import { GenericTableButtonComponent } from "../../s25-table/generics/generic.table.button.component";
import { jSith } from "../../../util/jquery-replacement";
import Cell = Table.Cell;
import { S25TableComponent } from "../../s25-table/s25.table.component";
import { Rules } from "../../s25-rule-tree/s25.rule.const";
import SourceItem = Rules.SourceItem;
import { AttachmentService } from "../../../services/attachment.service";
import { ProfileComponent } from "./profile.component";
import { S25Util } from "../../../util/s25-util";
import Column = Table.Column;

@TypeManagerDecorator("s25-ng-profile-list")
@Component({
    selector: "s25-ng-profile-list",
    template: `
        @if (init) {
            <s25-ng-checkbox class="ngBlock c-margin-bottom--single" [parentCd]="cd" [(modelValue)]="friendsOnly"
                >Friends Only</s25-ng-checkbox
            >

            <s25-ng-checkbox class="ngBlock c-margin-bottom--single" [parentCd]="cd" [(modelValue)]="excludeMatched"
                >Exclude Matched</s25-ng-checkbox
            >

            <div class="c-margin-bottom--single">
                <label
                    >First Name
                    <input class="c-input" [(ngModel)]="firstName" />
                </label>

                <label class="c-margin-left--half"
                    >Last Name
                    <input class="c-input" [(ngModel)]="lastName" />
                </label>
            </div>

            <s25-ng-dropdown-search-criteria
                [(chosen)]="selectedQuestion"
                [type]="'matchQuestions'"
                [customFilterValue]="$any(profile.seasonId)"
            ></s25-ng-dropdown-search-criteria>

            @if (selectedQuestion?.itemTypeId) {
                <label class="c-margin-top--single">
                    Answer:
                    <select
                        [(ngModel)]="selectedAnswer"
                        (ngModelChange)="addQuestionAnswer(selectedQuestion, selectedAnswer)"
                    >
                        @for (child of selectedQuestion.children; track child) {
                            <option [value]="child.itemId">
                                {{ child.itemName }}
                            </option>
                        }
                    </select>
                </label>
            }

            @for (questionAnswer of questionAnswers; track questionAnswer) {
                <div class="c-margin-top--single">
                    <div>{{ questionAnswer.question }}: {{ questionAnswer.answer }}</div>
                </div>
            }

            <s25-ng-button [buttonClass]="'ngBlock c-margin-top--half'" [type]="'primary'" [onClick]="refreshTable"
                >Search</s25-ng-button
            >
            <s25-ng-button [buttonClass]="'ngBlock c-margin-top--half'" [type]="'outline'" [onClick]="clearSearch"
                >Clear</s25-ng-button
            >

            <s25-ng-table
                [hidden]="!!viewedProfile"
                [caption]="'Roommate Search'"
                [unlimitedWidth]="true"
                [columnSortable]="false"
                [dataSource]="dataSource"
                [hasTotalRowCount]="true"
                [hasRefresh]="true"
                [showHeaderWhenNoData]="true"
            ></s25-ng-table>

            @if (viewedProfile) {
                <div class="c-margin-top--single viewedProfile">
                    <div class="c-margin-bottom--single">
                        {{ viewedProfile?.contact?.firstName }} {{ viewedProfile?.contact?.familyName }}
                    </div>
                    @if (viewedProfile.friend) {
                        @if (viewedProfile.friend.friendMsg) {
                            <div class="c-margin-bottom--single">
                                Friend Message: {{ viewedProfile.friend.friendMsg }}
                            </div>
                        }
                    }
                    @if (viewedProfile.imageUri) {
                        <div>
                            <span>Profile Picture: </span>
                            <img [src]="viewedProfile.imageUri" alt="Profile Picture" />
                        </div>
                    }
                    @for (answer of viewedProfile.answers; track answer) {
                        <div class="c-margin-bottom--single">
                            <div>{{ answer.question.question }}</div>
                            <div>{{ answer.longAnswer || answer.answer }}</div>
                        </div>
                    }
                    <a href="javascript:void(0)" (click)="backToSearchResults()">Back to Search Results</a>
                </div>
            }

            <div class="c-margin-top--triple">
                <h3 class="c-margin-bottom--single">Roommate Order</h3>
                <div class="sortContainer c-margin-bottom--single">
                    <div>Sort in order of your most preferred roommates</div>
                    <ul s25-ng-dnd-sortable [items]="outgoingFriends">
                        @for (friend of outgoingFriends; track friend.contId; let i = $index) {
                            <li s25-ng-dnd-sortable-item [index]="i">
                                <s25-ng-drag-handle></s25-ng-drag-handle>
                                <div>
                                    <span>{{ friend.firstName }} {{ friend.lastName }}</span>
                                    @if (friend.matched) {
                                        <span>&nbsp;(Matched)</span>
                                    }
                                    <s25-ng-button
                                        [buttonClass]="'c-margin-left--half'"
                                        [type]="'danger--outline'"
                                        [onClick]="removeFriend(friend)"
                                        >Remove</s25-ng-button
                                    >
                                </div>
                            </li>
                        }
                    </ul>
                </div>
                <s25-ng-button [type]="'primary'" [onClick]="saveRoommateOrder">Save Roommate Order</s25-ng-button>
            </div>
        }
    `,
    styles: `
        .viewedProfile {
            padding-top: 1.25rem;
            padding-left: 1.25rem;
            padding-right: 1.25rem;
            border: 1px solid black;
            border-radius: 10px;
            margin-left: auto;
            margin-right: auto;
            max-width: 50%;
        }

        s25-ng-drag-handle {
            margin: auto 0;
        }

        .sortContainer ul {
            padding-inline-start: 0;
        }

        .sortContainer ul li {
            list-style-type: none;
            padding: 0.5em 0;
            border-bottom: 1px solid #ddd;
            display: flex;
        }
    `,
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileListComponent implements OnInit {
    @Input() profile: MatchProfile;

    @ViewChild(S25TableComponent) table: S25TableComponent;

    dataSource: Table.Unpaginated;
    viewedProfile: MatchProfile;
    init = false;
    friendsOnly = false;
    excludeMatched = true;
    selectedQuestion: SourceItem;
    selectedAnswer: string;
    questionAnswers: { questionId: number; question: string; answer: string }[] = [];
    outgoingFriends: MatchFriendContact[] = [];
    firstName: string;
    lastName: string;
    questions: Question[] = [];

    constructor(public cd: ChangeDetectorRef) {}

    addQuestionAnswer = (question: SourceItem, answer: string) => {
        this.questionAnswers.push({ questionId: question.itemId, question: question.itemName, answer });
        this.selectedQuestion = null;
        this.selectedAnswer = null;
        this.cd.detectChanges();
    };

    getRows = async () => {
        let rows: Table.Row[] = [];
        let profiles = await StudentHousingService.getProfiles(
            this.profile.seasonId,
            this.profile.contId,
            this.firstName,
            this.lastName,
            this.questionAnswers,
            [],
            this.excludeMatched,
            false,
        );
        let friendContacts = await StudentHousingService.getFriends(this.profile.seasonId, this.profile.inviteHash);
        let friendMap = new Map<number, MatchFriendContact>();

        let desiredRoommates = new Set<number>();
        for (let roommate of this.profile.desiredRoommates) {
            desiredRoommates.add(roommate.roommateContId);
        }

        let saveOutgoing = false;
        for (let friend of friendContacts) {
            if (friend.outgoing && !this.outgoingFriends.find((f) => f.contId === friend.contId)) {
                if (!desiredRoommates.has(friend.contId)) {
                    saveOutgoing = true;
                }
                this.outgoingFriends.push(friend);
            }
            friendMap.set(friend.contId, friend);
        }
        saveOutgoing && this.saveRoommateOrder();

        // sort outgoing friends by desiredRoommates order
        this.outgoingFriends.sort((a, b) => {
            return (
                this.profile.desiredRoommates.find((r) => r.roommateContId === a.contId)?.sortOrder -
                this.profile.desiredRoommates.find((r) => r.roommateContId === b.contId)?.sortOrder
            );
        });

        if (profiles?.length) {
            jSith.forEach(profiles, (idx, profile: MatchProfile) => {
                if (profile.contId === this.profile.contId) {
                    return;
                }

                let friend = friendMap.get(profile.contId);
                if (this.friendsOnly && !friend) {
                    return;
                }
                profile.friend = friend;

                const cells: Table.Row["cells"] = {};
                for (let i = 0; i < this.dataSource.columns.length; i++) {
                    let column = this.dataSource.columns[i];
                    let cell: Cell = {};
                    if (column.id === "add_friend") {
                        cell.inputs = {
                            disabled: friend && friend.outgoing,
                            label: column.header,
                            type: "outline",
                        };
                        cell.outputs = {
                            click: (_, row, instance) => this.addFriend(row, instance),
                        };
                    } else if (column.id === "remove_friend") {
                        cell.inputs = {
                            disabled: !friend,
                            label: column.header,
                            type: "outline",
                        };
                        cell.outputs = {
                            click: (_, row, instance) => this.removeFriendRow(row, instance),
                        };
                    } else if (column.id === "firstName") {
                        cell.text = profile?.contact?.firstName ?? "";
                    } else if (column.id === "familyName") {
                        cell.text = profile?.contact?.familyName ?? "";
                    } else if (column.id === "mutualFriend") {
                        cell.text = profile.friend?.incoming && profile.friend?.outgoing ? "Yes" : "No";
                    } else if (S25Util.isNumeric(column.id)) {
                        let answer = profile.answers.find((a) => a.questionId === S25Util.parseInt(column.id));
                        cell.text = answer?.longAnswer || answer?.answer || "";
                    }
                    cells[column.id] = cell;
                }
                rows.push({
                    id: profile.contId,
                    name: profile.contId + "",
                    cells: cells,
                    data: profile,
                });
            });
        }
        this.cd.detectChanges();
        return {
            rows: rows,
            totalRows: rows.length,
        };
    };

    addFriend = async (row: Table.Row, instance?: GenericTableButtonComponent) => {
        if (!instance.disabled) {
            instance.disabled = true;
            row.cells.remove_friend.instance.disabled = false;

            let friendProfile = row.data as MatchProfile;
            let friendContId = friendProfile.contId;
            await StudentHousingService.addFriend(this.profile.inviteHash, this.profile.seasonId, {
                seasonId: this.profile.seasonId,
                condId: this.profile.contId,
                friendContId: friendContId,
            });

            let friendContacts = await StudentHousingService.getFriends(this.profile.seasonId, this.profile.inviteHash);
            for (let friend of friendContacts) {
                if (friend.contId === friendContId) {
                    if (!this.outgoingFriends.find((f) => f.contId === friend.contId)) {
                        this.outgoingFriends.push(friend);
                        this.saveRoommateOrder();
                    }
                    if (friend.incoming) {
                        friendProfile.friend = friend;
                        row.cells.mutualFriend.text = "Yes";
                    }
                    break;
                }
            }

            this.cd.detectChanges();
        }
    };

    removeFriendRow = async (row: Table.Row, instance?: GenericTableButtonComponent) => {
        if (!instance.disabled) {
            instance.disabled = true;
            let friendProfile = row.data as MatchProfile;
            let friendContId: number = friendProfile.contId;
            this.outgoingFriends = this.outgoingFriends.filter((f) => f.contId !== friendContId);
            this.saveRoommateOrder();
            row.cells.add_friend.instance.disabled = false;
            row.cells.mutualFriend.text = "No";
            await StudentHousingService.deleteFriend(this.profile.inviteHash, friendContId, this.profile.seasonId);
            friendProfile.friend = null;
            this.cd.detectChanges();
        }
    };

    removeFriend = (friend: MatchFriendContact) => {
        return async () => {
            this.outgoingFriends = this.outgoingFriends.filter((f) => f.contId !== friend.contId);
            await this.saveRoommateOrder();
            await StudentHousingService.deleteFriend(this.profile.inviteHash, friend.contId, this.profile.seasonId);
            await this.refreshTable();
        };
    };

    view = async (row: Table.Row, instance?: GenericTableButtonComponent) => {
        this.viewedProfile = row.data as MatchProfile;
        this.viewedProfile.imageUri = await AttachmentService.getFileTextIfExists(
            3,
            this.viewedProfile.contId,
            ProfileComponent.profilePictureFileName,
            "inline",
        );
        this.cd.detectChanges();
    };

    backToSearchResults = () => {
        this.viewedProfile = null;
        this.cd.detectChanges();
    };

    refreshTable = async () => {
        this.cd.detectChanges();
        await this.table.refresh();
        this.cd.detectChanges();
    };

    saveRoommateOrder = async () => {
        const newDesiredRoommates = this.outgoingFriends.map((f) => {
            return {
                contId: this.profile.contId,
                roommateContId: f.contId,
                sortOrder: this.outgoingFriends.indexOf(f) + 1,
            };
        });
        await StudentHousingService.setRoommatePrefs(this.profile.inviteHash, newDesiredRoommates);
        this.profile.desiredRoommates.splice(0, this.profile.desiredRoommates.length);
        [].push.apply(this.profile.desiredRoommates, newDesiredRoommates);
        this.profile.desiredRoommates.sort(S25Util.shallowSort("sortOrder", true));
        this.cd.detectChanges();
    };

    clearSearch = () => {
        this.selectedQuestion = null;
        this.selectedAnswer = null;
        this.questionAnswers = [];
        return this.refreshTable();
    };

    async ngOnInit() {
        this.questions = await StudentHousingService.getQuestions(this.profile.seasonId);
        let questionCols: Column[] = this.questions
            .filter((q) => q.questionType === "SELECT" && q.questionLevel === "SEARCH_COLUMN")
            .map((q) => {
                return {
                    id: q.questionId + "",
                    header: q.question,
                };
            });
        let columns: Column[] = [
            {
                id: "firstName",
                header: "First Name",
            },
            {
                id: "familyName",
                header: "Last Name",
            },
            {
                id: "mutualFriend",
                header: "Mutual",
            },
        ];
        columns = columns.concat(questionCols);
        columns = columns.concat([
            GenericTableButtonComponent.Column("View", this.view),
            GenericTableButtonComponent.Column("Add Friend", this.addFriend, "outline", "min-content", false),
            GenericTableButtonComponent.Column("Remove Friend", this.removeFriendRow, "outline", "min-content", true),
        ]);

        this.dataSource = {
            type: "unpaginated",
            dataSource: this.getRows,
            columns: columns,
        };

        this.init = true;
        this.cd.detectChanges();
    }
}
